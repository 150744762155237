import {defineStore} from "pinia";

interface HotStorage {
    items:  { [key: string]: HotStorageItem; }
}

interface HotStorageItem {
    data: any;
    expiry: Date;
}

const hotStorage = defineStore('hot', {
    state: (): HotStorage => ({
        items: {},
    }),
    actions: {
        add(key: string, data: any, minutes: number) {
            const expiry = new Date(new Date().getTime() + minutes * 60000);

            this.items[key] = {
                data,
                expiry
            };
        },
        get(key: string) {
            const item = this.items[key];
            if (!item) {
                return null;
            }

            if (item.expiry >= new Date()) {
                return item.data;
            }

            delete this.items[key];

            return null;
        }
    },
    persist: true
});

export default hotStorage;