import {defineStore} from "pinia";
import HttpService from "@/services/http.service";
import {Role, TeamUser, User, UserReadOnly} from "@/interfaces/user";
import authorizationStore from "@/store/authorization.store";
import hotStorage from "@/store/hot.storage";

interface UserStore {
    user: User
}

const userStore = defineStore('user', {
    state: (): UserStore => ({
        user: null
    }),
    actions: {
        async loadUser(): Promise<void> {
            const authStore = authorizationStore();

            await HttpService.get('media-scout/initial-contracts')


            this.user = await this.getById(authStore.id);
        },
        onLogout() {
            this.user = null;
        },
        haveAccess(role: Role) {
            return this.user.role.some(r => r === role)
        },
        async getById(id: number): Promise<User> {

            return await HttpService.get<User>(`user/editable/${id}`);
        },
        async getMany(): Promise<UserReadOnly[]> {
            return HttpService.get<UserReadOnly[]>('user/table');
        },
        async create(user: User): Promise<User> {
            return await HttpService.put<User>('user', user);
        },
        async save(user: User): Promise<User> {
            return await HttpService.post<User>('user', user);
        },
        async delete(id: number): Promise<boolean> {
            await HttpService.delete(`user/${id}`);

            return true;
        },
        async getTeamUsers(): Promise<TeamUser[]> {
            const hotStore = hotStorage();

            let users = hotStore.get('teamUsers');
            if (!users) {
                users = await HttpService.get<TeamUser[]>('user/team');

                hotStore.add('teamUsers', users, 60);
            }

            return users;
        },
    }
})

export default userStore;