

import {Options, Vue} from "vue-class-component";
import router from "@/router";

@Options({
    components: {}
})
export default class ErrorView extends Vue {

    refreshPage() {
        router.push('/')
    }
}

