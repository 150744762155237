import {defineStore} from "pinia";
import {AppSettings} from "@/interfaces/app.settings";
import HttpService from "@/services/http.service";

interface AppStoreState {
    settings: AppSettings;
}

const appStore = defineStore('app', {
    state: (): AppStoreState => ({
        settings: null
    }),
    actions: {
        async loadSettings(): Promise<void> {
            this.settings = await HttpService.get<AppSettings>('app');
            if (!this.settings) {
                throw new Error(`Can't load settings`);
            }
        },
        applyRouteGuard(route) {
            const routeGuard = this.settings.routes.find(r => r.name === route.name);
            if (routeGuard) {
                route.meta.canRead = routeGuard.canRead;
                route.meta.canEdit = routeGuard.canEdit;
                route.meta.canDelete = routeGuard.canDelete;
            }
        }
    }
})

export default appStore;