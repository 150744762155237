import {defineStore} from "pinia";
import axiosInstance from '@/services/axios';
import userStore from "@/store/user.store";
import router from "@/router";
import hotStorage from "@/store/hot.storage";
import activeTabsStorage from "@/store/active-tabs.storage";
import HttpService from "@/services/http.service";
import {SignInResponse, Tokens} from "@/interfaces";

interface AuthorizationState {
    id: number;
    accessToken: string;
    refreshToken: string;
    returnUrl: string;
}

const authorizationStore = defineStore('authorization', {
    state: (): AuthorizationState => ({
        id: 0,
        accessToken: '',
        refreshToken: '',
        returnUrl: ''
    }),
    getters: {
        isLoggedIn: (state) => state.accessToken.length > 0 && state.refreshToken.length > 0
    },
    actions: {
        async signIn(login: string, password: string) {
            try {
                const response = await HttpService.post<SignInResponse>('auth/signIn', {login, password})
                if (!response?.id)
                    return false;

                this.id = response.id;
                this.accessToken = response.accessToken;
                this.refreshToken = response.refreshToken;
            } catch (_) {
                return false;
            }

            return true;
        },
        async signInWithRT() {
            this.accessToken = this.refreshToken;

            try {
                const response = await HttpService.get<Tokens>('auth/refresh')
                if (!response?.accessToken)
                    return false;

                this.accessToken = response.accessToken;
                this.refreshToken = response.refreshToken;
            } catch (_) {
                return false;
            }

            return true;
        },
        async logout() {
            try {
                await  HttpService.post('auth/logout', {});
            } catch (_) {
                // ignore
            }

            this.id = 0;
            this.accessToken = '';
            this.refreshToken = '';

            const store = userStore();
            const hotStore = hotStorage();
            const activeTabs = activeTabsStorage();

            store.onLogout();

            hotStore.$reset();
            activeTabs.$reset();

            await router.push('/login');
        }
    },
    persist: true
})

export default authorizationStore;