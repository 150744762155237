

import {Options, Vue} from "vue-class-component";
import router from "@/router";
import authorizationStore from "@/store/authorization.store";

@Options({
})
export default class AuthView extends Vue {
    isBusy = false;

    login = '';
    password = '';

    isPasswordVisible = false;

    authService = authorizationStore();

    async signIn() {
        this.isBusy = true;

        const isLoggedIn = await this.authService.signIn(this.login, this.password);
        if (isLoggedIn) {
            await router.push(this.authService.returnUrl || '/');

            this.authService.returnUrl = null;
        }

        this.isBusy = false;
    }
}
