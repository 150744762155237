import axiosInstance from '../services/axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const setup = (authStore, toolbarStore) => {
    axiosInstance.interceptors.request.use((config) => {
        const token = authStore.accessToken;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return Promise.resolve(config);
    }, error => {
        return Promise.reject(error);
    });

    createAuthRefreshInterceptor(axiosInstance, async (failedRequest) => {
        const isRefreshed = await authStore.signInWithRT();
        if (!isRefreshed) {
            authStore.logout();

            return Promise.reject();
        }

        const token = authStore.accessToken;

        failedRequest.response.config.headers['Authorization'] = `Bearer ${token}`;
    }, {
        interceptNetworkError: true,
        pauseInstanceWhileRefreshing: true
    });
};

export default setup;