import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthView from "../views/AuthView.vue";
import ErrorView from "@/views/ErrorPage.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/login",
        name: "login",
        component: AuthView,
        meta: {
            title: "Авторизация",
            requiresAuth: false
        }
    },
    {
        path: "/error",
        name: "error",
        component: ErrorView,
        meta: {
            title: "Ошибка",
            requiresAuth: false
        }
    },
    { path: '/:catchAll(.*)', redirect: '/' },
    {
        name: "shell",
        path: "/",
        component: () => import(/* webpackChunkName: "shell" */ "../views/ShellView.vue"),
        children: [
            {
                path: "/",
                name: "dashboard",
                component: () => import(/* webpackChunkName: "dashboard" */ "../views/pages/DashboardView.vue"),
                strict: true,
                meta: {
                    title: "Рабочий стол",
                    requiresAuth: true,
                    isRootRoute: true,
                }
            },
            {
                path: "/profile",
                name: "profile",
                component: () => import(/* webpackChunkName: "profile" */ "../views/pages/Profile.vue"),
                strict: true,
                meta: {
                    title: "Профиль",
                    requiresAuth: true,
                    isRootRoute: true,
                }
            },
            {
                path: "/offers",
                name: "offers",
                component: () => import(/* webpackChunkName: "offers" */ "../views/pages/offers/Offers.vue"),
                strict: true,
                meta: {
                    title: "Офферы",
                    requiresAuth: true,
                    isRootRoute: true,
                }
            },
            {
                path: "/offers/:id",
                name: "offer",
                component: () => import(/* webpackChunkName: "offers" */ "../views/pages/offers/Offer.vue"),
                strict: true,
                meta: {
                    title: "Редактирование оффера",
                    requiresAuth: true,
                    canRead: false
                }
            },
            {
                path: "/tasks",
                name: "tasks",
                component: () => import(/* webpackChunkName: "tasks" */ "../views/pages/tasks/Tasks.vue"),
                strict: true,
                meta: {
                    title: "Задачи",
                    requiresAuth: true,
                    isRootRoute: true,
                }
            },
            {
                path: "/tasks/:id",
                name: "task-editor",
                component: () => import(/* webpackChunkName: "tasks" */ "../views/pages/tasks/Task.vue"),
                strict: true,
                meta: {
                    title: "Задача",
                    requiresAuth: true
                }
            },
            {
                path: "/tasks/readonly/:id",
                name: "task-readonly",
                component: () => import(/* webpackChunkName: "tasks" */ "../views/pages/tasks/TaskReadOnly.vue"),
                strict: true,
                meta: {
                    title: "Задача",
                    requiresAuth: true
                }
            },
            {
                path: "/creative-projects",
                name: "creative-projects",
                component: () => import(/* webpackChunkName: "creative" */ "../views/pages/creative-projects/CreativeProjects.vue"),
                strict: true,
                meta: {
                    title: "Креативные проекты",
                    requiresAuth: true
                }
            },
            {
                path: "/creative-projects/:id",
                name: "creative-project",
                component: () => import(/* webpackChunkName: "creative" */ "../views/pages/creative-projects/CreativeProject.vue"),
                strict: true,
                meta: {
                    title: "Креативные проекты",
                    requiresAuth: true
                }
            },
            {
                path: "/creatives",
                name: "creatives",
                component: () => import(/* webpackChunkName: "creative" */ "../views/pages/creatives/Creatives.vue"),
                strict: true,
                meta: {
                    title: "Креативы",
                    requiresAuth: true
                }
            },
            {
                path: "/creatives/:id",
                name: "creative",
                component: () => import(/* webpackChunkName: "creative" */ "../views/pages/creatives/Creative.vue"),
                strict: true,
                meta: {
                    title: "Креатив",
                    requiresAuth: true
                }
            },
            {
                path: "/faq",
                name: "faq",
                component: () => import(/* webpackChunkName: "faq" */ "../views/pages/faq/FaqList.vue"),
                strict: true,
                meta: {
                    title: "FAQ",
                    requiresAuth: true,
                    isRootRoute: true,
                }
            },
            {
                path: "/faq/:id",
                name: "faq-item",
                component: () => import(/* webpackChunkName: "faq" */ "../views/pages/faq/FaqItem.vue"),
                strict: true,
                meta: {
                    title: "FAQ",
                    requiresAuth: true
                }
            },
            {
                path: "/users",
                name: "users",
                component: () => import(/* webpackChunkName: "users" */ "../views/pages/users/Users.vue"),
                strict: true,
                meta: {
                    title: "Пользователи",
                    requiresAuth: true
                }
            },
            {
                path: "/users/:id",
                name: "user",
                component: () => import(/* webpackChunkName: "users" */ "../views/pages/users/User.vue"),
                strict: true,
                meta: {
                    title: "Пользователь",
                    requiresAuth: true
                }
            },
            {
                path: "/advertising-data",
                name: "advertising-datas",
                component: () => import("../views/pages/advertising-data/Advertising-datas.vue"),
                strict: true,
                meta: {
                    title: "Рекламные данные",
                    requiresAuth: true
                }
            },
            {
                path: "/advertising-data/:id",
                name: "advertising-data",
                component: () => import("../views/pages/advertising-data/Advertising-data.vue"),
                strict: true,
                meta: {
                    title: "Клиент",
                    requiresAuth: true,
                    canRead: false
                }
            },
            {
                path: "/customers",
                name: "customers",
                component: () => import(/* webpackChunkName: "offers" */ "../views/pages/media-scout/customers/Customers.vue"),
                strict: true,
                meta: {
                    title: "Заказчики",
                    requiresAuth: true,
                    isRootRoute: true,
                }
            },
            {
                path: "/final-contracts",
                name: "final-contracts",
                component: () => import(/* webpackChunkName: "offers" */ "../views/pages/media-scout/final-contracts/FinalContracts.vue"),
                strict: true,
                meta: {
                    title: "Доходные договоры",
                    requiresAuth: true,
                    isRootRoute: true,
                }
            },
            {
                path: "/final-contract/:id",
                name: "final-contract",
                component: () => import(/* webpackChunkName: "offers" */ "../views/pages/media-scout/final-contracts/FinalContract.vue"),
                strict: true,
                meta: {
                    title: "Доходный договор",
                    requiresAuth: true,
                    isRootRoute: true,
                }
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
