import {defineStore} from "pinia";
import {Subject, Subscription} from "rxjs";
import {RouteLocationNormalizedLoaded} from "vue-router";

interface ToolbarState {
    onSaveSubscription: Subscription;
    onCloseSubscription: Subscription;
    onDeleteSubscription: Subscription;
    onCreateSubscription: Subscription;
    onMinimizeSubscription: Subscription;
    onSave: Subject<void>;
    onClose: Subject<RouteLocationNormalizedLoaded>;
    onCreate: Subject<void>;
    onDelete: Subject<void>;
    onMinimize: Subject<void>;
    isBusy: boolean;
    isSuccessSnackVisible: boolean;
    successSnackText: string;
    isErrorSnackVisible: boolean;
    errorSnackText: string;
    isNavBarVisible: boolean;
}

const toolbarStore = defineStore('toolbar', {
    state: (): ToolbarState => ({
        onSaveSubscription: null,
        onCloseSubscription: null,
        onDeleteSubscription: null,
        onCreateSubscription: null,
        onMinimizeSubscription: null,
        onSave: new Subject<void>(),
        onCreate: new Subject<void>(),
        onClose: new Subject<RouteLocationNormalizedLoaded>(),
        onDelete: new Subject<void>(),
        onMinimize: new Subject<void>(),
        isBusy: false,
        isSuccessSnackVisible: false,
        successSnackText: '',
        isErrorSnackVisible: false,
        errorSnackText: '',
        isNavBarVisible: true
    }),
    getters: {
        isSaveButtonVisible: state => state.onSaveSubscription != null,
        isCloseButtonVisible: state => state.onCloseSubscription != null,
        isDeleteButtonVisible: state => state.onDeleteSubscription != null,
        isCreateButtonVisible: state => state.onCreateSubscription != null,
        isMinimizeButtonVisible: state => state.onMinimizeSubscription != null,
    },
    actions: {
        emitOnSave() {
            this.onSave.next();
        },
        subscribeOnSaveButton(onSave: () => void) {
            this.onSaveSubscription = this.onSave.subscribe(() => {
                onSave();
            });
        },
        showErrorSnack(text: string) {
            this.errorSnackText = text;
            this.isErrorSnackVisible = true;
        },
        showSuccessSnack(text: string) {
            this.successSnackText = text;
            this.isSuccessSnackVisible = true;
        },
        unsubscribeFromSaveButton() {
            if (!this.onSaveSubscription) {
                return;
            }

            this.onSaveSubscription.unsubscribe();
            this.onSaveSubscription = null;
        },
        emitOnClose() {
            this.onClose.next();
        },
        subscribeOnCloseButton(onClose: (routing: RouteLocationNormalizedLoaded) => void) {
            this.onCloseSubscription = this.onClose.subscribe((routing) => {
                onClose(routing);
            });
        },
        unsubscribeFromCloseButton() {
            if (!this.onCloseSubscription) {
                return;
            }

            this.onCloseSubscription.unsubscribe();
            this.onCloseSubscription = null;
        },
        emitOnDelete() {
            this.onDelete.next();
        },
        subscribeOnDeleteButton(onDelete: () => void) {
            this.onDeleteSubscription = this.onDelete.subscribe(() => {
                onDelete();
            });
        },
        unsubscribeFromDeleteButton() {
            if (!this.onDeleteSubscription) {
                return;
            }

            this.onDeleteSubscription.unsubscribe();
            this.onDeleteSubscription = null;
        },
        emitOnCreate() {
            this.onCreateSubscription.next();
        },
        subscribeOnCreateButton(onCreate: () => void) {
            this.onCreateSubscription = this.onCreate.subscribe(() => {
                onCreate();
            });
        },
        unsubscribeFromCreateButton() {
            if (!this.onCreateSubscription) {
                return;
            }

            this.onCreateSubscription.unsubscribe();
            this.onCreateSubscription = null;
        },
        emitOnMinimize() {
            this.onMinimize.next();
        },
        subscribeOnMinimizeButton(onMinimize: () => void) {
            this.onMinimizeSubscription = this.onMinimize.subscribe(() => {
                onMinimize();
            });
        },
        unsubscribeFromMinimizeButton() {
            if (!this.onMinimizeSubscription) {
                return;
            }

            this.onMinimizeSubscription.unsubscribe();
            this.onMinimizeSubscription = null;
        },
        unsubscribe() {
            this.unsubscribeFromSaveButton();
            this.unsubscribeFromCloseButton();
            this.unsubscribeFromDeleteButton();
            this.unsubscribeFromCreateButton();
            this.unsubscribeFromMinimizeButton();
        },
    }
})

export default toolbarStore;