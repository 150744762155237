import {AxiosRequestConfig} from "axios";
import axiosInstance from '../services/axios';

export default new class HttpService {
    async get<T>(url: string, params?: AxiosRequestConfig): Promise<T> {
        try {
            if (!params) {
                params = {};
            }

            const response = await axiosInstance.get(url, params);

            return response.data.data as T;
        } catch (error) {
            console.error(error);

            return null;
        }
    }

    async post<T>(url: string, data: any): Promise<T> {
        data = data ?? {};

        try {
            const response = await axiosInstance.post(url, data);

            return response.data.data as T;
        } catch (error) {
            console.error(error);

            return null;
        }
    }

    async put<T>(url: string, data: any): Promise<T> {
        data = data ?? {};

        try {
            const response = await axiosInstance.put(url, data);

            return response.data.data as T;
        } catch (error) {
            console.error(error);

            return null;
        }
    }

    async delete(url): Promise<boolean> {
        try {
            const response = await axiosInstance.delete(url);

            return response.data.status;
        } catch (error) {
            console.error(error);

            return null;
        }
    }
}
