import {defineStore} from "pinia";
import {ActiveTab} from "@/interfaces/active-tab";
import {useRoute} from "vue-router";

interface ActiveTabs {
    tabs: ActiveTab[];
}

const activeTabsStorage = defineStore('active-tabs', {
    state: (): ActiveTabs => ({
        tabs: [],
    }),
    actions: {
        getTab(id: string) {
            return this.tabs.find(x => x.id === id);
        },
        getTabByUrl(url: string) {
            return this.tabs.find(x => x.url === url);
        },
        addTab(tab: ActiveTab) {
            const existedTab = this.tabs.find(t => t.id === tab.id);
            if (existedTab) {
                this.updateTab(tab.id, tab.name, tab.data);

                return;
            }

            this.tabs.unshift(tab);
        },
        updateTab(id: string, name: string, data: any) {
            const existedTab = this.tabs.find(t => t.id === id);
            if (existedTab) {
                existedTab.data = data;
                existedTab.name = name;
            }

            return;
        },
        removeTab(tab: ActiveTab) {
            this.tabs = this.tabs.filter(t => t.name != tab.name);
        },
        removeTabByUrl(url: string) {
            const tab = this.getTabByUrl(url);
            if (tab) {
                this.removeTab(tab);
            }
        }
    },
    persist: true
});

export default activeTabsStorage;