import { createApp } from "vue";
import axios from 'axios'
import VueAxios from 'vue-axios'
import BalmUI from 'balm-ui'; // Official Google Material Components
import BalmUIPlus from 'balm-ui/dist/balm-ui-plus'; // BalmJS Team Material Components
import 'balm-ui-css';

import "@vueform/multiselect/themes/default.css"

import VSwitch from 'v-switch-case'

import App from './App.vue'
import router from './router'

import {createPinia} from "pinia";
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

import 'vuetify/styles'
import { createVuetify, ThemeDefinition  } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, md } from 'vuetify/iconsets/md'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import vue3Editor from 'vue3-editor'

import setupInterceptors from './interceptors/axios.interceptors';
import authorizationStore from "@/store/authorization.store";
import toolbarStore from "@/store/toolbar.store";

import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';

const myCustomLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        primary: '#FFB800',
    }
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
        }
    },
    icons: {
        defaultSet: 'md',
        aliases,
        sets: {
            md,
        }
    },
});

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);

pinia.use(piniaPluginPersistedState);

setupInterceptors(authorizationStore(), toolbarStore());

app.use(PrimeVue);
app.component('Calendar', Calendar);

app.use(vue3Editor)
app.use(vuetify);
app.use(VueAxios, axios);
app.use(BalmUI);
app.use(BalmUIPlus);

app.use(VSwitch);

app.use(router);

app.mount('#app')

